import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/app";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: () => import("../views/Main/Main"),
    children: [
      {
        path: "/calculator",
        name: "Calculator",
        props: true,
        components: {
          MainComponent: () =>
            import("../calculator/components/Calculator.vue"),
        },
      },
      {
        path: "/checks",
        name: "Checks",
        props: true,
        components: {
          MainComponent: () => import("../views/Checks/Checks.vue"),
        },
      },
      {
        path: "/bankNews",
        name: "BankNews",
        props: true,
        components: {
          MainComponent: () => import("../views/BankNews/BankNews.vue"),
        },
      },
      {
        path: "/analytics",
        name: "Analytics",
        props: true,
        components: {
          MainComponent: () => import("../views/Analytics/Analytics.vue"),
        },
      },
      {
        path: "/insurance",
        name: "Insurance",
        props: true,
        components: {
          MainComponent: () => import("../views/Insurance/InsurancePage.vue"),
        },
      },
      {
        path: "/directory",
        name: "Directory",
        props: true,
        components: {
          MainComponent: () => import("../views/Directory/Directory.vue"),
        },
      },
    ],
  },
  {
    path: "/about",
    redirect: { name: "Main" },
  },
  {
    path: "/juristicDocuments",
    name: "JuristicDocuments",
    component: () => import("../views/JuristicDocuments/JuristicDocuments.vue"),
  },
  {
    path: "/call-request",
    redirect: () => {
      return { name: "Main", params: { modal: true }, hash: "#info" };
    },
  },
  {
    path: "/auth/registration",
    name: "Registration",
    component: () => import("../views/Auth/Registration/Registration"),
  },
  {
    path: "/presentation",
    name: "Presentation",
    component: () =>
      import("../views/Auth/Registration/RegistrationWithPresentation"),
  },
  {
    path: "/auth",
    redirect: { name: "Login" },
  },
  {
    path: "/auth/login",
    name: "Login",
    component: () => import("../views/Auth/Login/Login"),
  },
  {
    path: "/auth/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/Auth/ForgotPassword/ForgotPassword"),
  },
  {
    path: "/auth/set-new-password",
    name: "ResetPassword",
    component: () => import("../views/Auth/ResetPassword/ResetPassword"),
  },
  {
    path: "/auth/logout",
    name: "Logout",
    component: () => import("../views/Auth/Logout"),
  },
  {
    path: "/tariffs",
    name: "Tariffs",
    props: true,
    component: () => import("../views/Tariffs/Tariffs"),
  },
  // {
  //   path: "/representative",
  //   name: "Representative",
  //   component: () => import("../views/Representatives/Representatives"),
  // },  Страница "Партнерство"
  {
    path: "/timepad/:id",
    name: "TimepadID",
    component: () => import("../views/Timepad/Timepad"),
  },
  {
    path: "/timepad",
    name: "Timepad",
    component: () => import("../views/Timepad/Timepad"),
  },
  {
    path: "/send-order",
    name: "SendOrder",
    components: {
      Authorized: () => import("../views/SendOrderPage/SendOrderPage"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support",
    name: "TechnicalSupport",
    component: () => import("../views/TechnicalSupport/TechnicalSupport"),
  },
  {
    path: "/support/:id",
    name: "TechnicalSupportId",
    component: () =>
      import(
        "../components/TechnicalSupport/TechnicalSupportId/TechnicalSupportId"
      ),
  },
  {
    path: "/points-history",
    name: "PointsHistory",
    components: {
      Authorized: () => import("../views/PointsHistory/PointsHistory"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account",
    name: "Account",
    components: {
      Authorized: () => import("@/views/Account/Account"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit-account",
    name: "EditAccount",
    components: {
      Authorized: () => import("@/views/Account/Account"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/news/:id",
    name: "News",
    component: () => import("../views/News/NewsId"),
  },
  {
    path: "/influence/preview",
    name: "InfluencePreview",
    component: () => import("../components/InfluenceModal/InfluenceID"),
  },
  {
    path: "/report",
    name: "Report",
    components: {
      Authorized: () => import("../views/Report/Report"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bonus",
    name: "BonusRegistration",
    component: () =>
      import("../views/BonusAuth/BonusRegistration/BonusRegistration.vue"),
  },
  {
    path: "*",
    redirect: () => {
      window.location.href = "https://new.banki.shop/backend/error/404";
    },
  },
  {
    name: "404",
    path: "/404",
    component: () => import("@/components/404/PageNotFound"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.user.userLogged) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (store.state.user.userLogged && to.path.includes("auth")) {
    next({ name: "Main" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (store.state.user.userLogged && to.path.includes("presentation")) {
    next({ name: "Main" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Main" && from.name !== "Login") {
    store.commit("toggleOverlay", true);
    setTimeout(() => store.commit("toggleOverlay", false), 15000);
  }
  next();
});

router.afterEach((to, from) => {
  if (to.name !== "Main" && from.name !== "Login") {
    store.commit("toggleOverlay", false);
  }
});

router.beforeEach((to, from, next) => {
  /** Страницы личного кабинета */
  const accountPages = ["Calculator", "SendOrder"];
  const isCabinet = accountPages.some((page) => page === to.name);
  store.commit("setIsCabinet", isCabinet);

  /** Страницы с отключенным футером и хедером */
  store.commit("setWithoutNav", false);

  /** Страницы с отключенным влиянием */
  const influenceDisable = ["InfluencePreview"];
  const influenceDisabled = influenceDisable.some((page) => page === to.name);
  store.commit("writeInfluenceField", { disabled: influenceDisabled });

  next();
});

export default router;
