import Cookies from "js-cookie";
export const GetUrl = (urlParams) => {
    const isWidget = process.env["VUE_APP_IS_WIDGET"] === "true";
    const isDev = process.env["VUE_APP_IS_DEV"] === "true";
    let url = undefined;
    const params = urlParams.split("?");
    const queryParams = params[1] ? `?${params[1]}` : "";
    const devURL = isWidget
        ? window.location.origin + "/widget"
        : window.location.origin + "/";
    const appBaseURL = isDev ? devURL : process.env["VUE_APP_BASE_URL"];
    if (isWidget) {
        let baseUrl = document.getElementById("widgetConfig").dataset.endpoint;
        if (isDev) {
            url = new URL(`${queryParams}`, baseUrl.length ? baseUrl : appBaseURL);
        }
        else {
            url = new URL(`${queryParams}`, baseUrl ?? process.env["VUE_APP_DATA_ENDPOINT"]);
        }
        url.searchParams.set("a", params[0]);
    }
    else if (!isWidget) {
        url = new URL(`/backend${urlParams}`, appBaseURL);
    }
    if (process.env["VUE_APP_IS_DEV"] === "true") {
        url?.searchParams.append("XDEBUG_SESSION_START", Cookies.get("XDEBUG_SESSION") ?? "");
    }
    return url ? url.toString() : "";
};
