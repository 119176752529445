import { AdditionalParametersCategory } from "@/calculator/shared/Classes/AdditionalParametersCategory";
import { Promotion, promoTypeAndPriority, } from "@/calculator/shared/Classes/PromotionClass";
import { AdditionalParametersCategoryForm } from "@/calculator/shared/Classes/AdditionalParametersCategoryForm";
import { newCategoryInput } from "@/calculator/components/ParametersBlock/ParametersHandler/Category";
export const calcMutations = {
    writeToken(state, data) {
        state.token = data;
    },
    toggleLoadingModules(state, bool) {
        state.modules["loadingModules"] = bool;
    },
    writeAvailableModules(state, data) {
        Object.keys(data).forEach((module) => (state.modules[module] = { ...data[module] }));
    },
    writeFakeAvailableModules(state) {
        for (let module in state.modules) {
            state.modules[module] = true;
        }
    },
    writeGuestFakeAvailableModules(state) {
        state.modules.calculator = true;
        state.modules.banksList = true;
        state.modules.promos = true;
    },
    showLoader(state, bool) {
        if (bool) {
            state.loaderWaiters++;
        }
        else if (state.loaderWaiters > 0) {
            state.loaderWaiters--;
        }
        state.loader = state.loaderWaiters > 0;
    },
    // value имеет любой тип
    writeParameters(state, data) {
        const id = data.id, isArray = Array.isArray(data.value), oldParameter = state.parameters[id]
            ? state.parameters[id]
            : {
                parameter: id,
                value: isArray ? [] : undefined,
            }, newParameter = {
            parameter: id,
            value: isArray || data.value === undefined ? data.value : +data.value,
        };
        const needComparing = [newParameter.value, oldParameter.value].includes(undefined) ||
            oldParameter.value.length === newParameter.value.length;
        let valuesAreEqual = false;
        if (needComparing) {
            valuesAreEqual = isArray
                ? oldParameter.value.every((v) => newParameter.value.includes(v))
                : oldParameter.value === newParameter.value;
        }
        state.stopRequest = valuesAreEqual;
        if (newParameter.value === undefined) {
            delete state.parameters[id];
        }
        if (!valuesAreEqual && newParameter.value !== undefined) {
            state.parameters[id] = newParameter;
        }
    },
    writeFinancialBurden(state, data) {
        if (data.value === undefined) {
            delete state.financialBurden[data.id];
        }
        else {
            state.financialBurden[data.id] = data.value;
        }
    },
    clearParameters(state) {
        state.parameters = {};
        state.categories = [];
    },
    writeAdditionalParametersCategories(state, categories) {
        state.additionalParametersCategories = [];
        categories?.forEach((category) => {
            const additionalParametersCategory = new AdditionalParametersCategory(category);
            // @ts-ignore
            state.additionalParametersCategories[category.id] =
                additionalParametersCategory;
            // @ts-ignore
            additionalParametersCategory.getAllParameters().forEach((parameter) => {
                state.additionalParameters[parameter.id] = parameter;
            });
        });
        buildAdditionalParametersCategoriesForm(state);
    },
    writeCheckedAdditionalParameters(state, data) {
        if (data.value === undefined) {
            delete state.checkedAdditionalParameters[data.id];
        }
        else {
            state.checkedAdditionalParameters[data.id] = data.value;
            state.checkedAdditionalParameters = {
                ...state.checkedAdditionalParameters,
            };
        }
    },
    clearAdditionalParameters(state) {
        let ids = Object.keys(state.checkedAdditionalParameters);
        ids.forEach((id) => {
            delete state.parameters[id];
        });
        state.checkedAdditionalParameters = {};
    },
    writeResultsWithIncome(state, data) {
        state.resultWithIncome = data;
    },
    writeResults(state, data) {
        state.result = data;
    },
    writeClientVersion(state, data) {
        state.clientVersion = data;
    },
    clearResult(state) {
        state.result = {
            count: 0,
            rates: [0, 0],
            payments: [0, 0],
            incomes: [0, 0],
            light: null,
            isLight: true,
            banks: [],
            rules: {
                Block: [],
                Show: [],
            },
            max: null,
            comboRate: null,
        };
    },
    writeInsuranceParameters(state, data) {
        let buildAge = state.parameters[210]?.value ??
            state.parameters[211]?.value ??
            state.parameters[212]?.value ??
            state.parameters[552]?.value;
        if (!buildAge) {
            buildAge = state.parameters[592]?.value === 1412 ? -1 : 0;
        }
        let fireDanger = state.parameters[1096]
            ? state.parameters[1096]?.value.includes(705) ||
                state.parameters[1096]?.value.includes(706)
            : false;
        const isGas = data.hasGas ? "hasGas" : "notHasGas";
        if (state.insuranceParameters[data.polisOnlineCode] === undefined) {
            state.insuranceParameters[data.polisOnlineCode] = {
                hasGas: {},
                notHasGas: {},
                currentHasGas: false,
            };
        }
        state.insuranceParameters[data.polisOnlineCode][isGas] = {
            buildAge: buildAge,
            hasGas: data.hasGas,
            bankCode: data.polisOnlineCode,
            creditSum: state.parameters[151].value,
            clientAge: state.parameters[195]?.value ?? state.parameters[196]?.value,
            gender: state.parameters[386].value === 1139 ? "F" : "M",
            fireDanger: fireDanger,
            ownership: state.parameters[592]?.value === 1413,
            creditStartDate: data.polisOnlineCode === "vtb",
            regionId: data.polisOnlineCode === "vtb" ? state.parameters[0].value : -1, // id региона если банк - ВТБ, иначе -1
        };
        state.insuranceParameters[data.polisOnlineCode]["currentHasGas"] =
            data.hasGas;
    },
    writeInsuranceResult(state, data) {
        const currentInsuranceResult = state.insuranceResult.find((el) => el.id === data.polisOnlineCode);
        let result = {
            hasGas: [],
            notHasGas: [],
        };
        let status = {
            hasGas: "loading",
            notHasGas: "loading",
        };
        if (currentInsuranceResult) {
            if (data.isGas === "hasGas") {
                result = {
                    hasGas: data.result,
                    notHasGas: currentInsuranceResult.result["notHasGas"],
                };
                status = {
                    hasGas: data.status,
                    notHasGas: currentInsuranceResult.status["notHasGas"],
                };
            }
            else {
                result = {
                    hasGas: currentInsuranceResult.result["hasGas"],
                    notHasGas: data.result,
                };
                status = {
                    hasGas: currentInsuranceResult.status["hasGas"],
                    notHasGas: data.status,
                };
            }
            const index = state.insuranceResult.findIndex((el) => el.id === data.polisOnlineCode);
            state.insuranceResult.splice(index, 1);
        }
        state.insuranceResult.push({
            bankId: data.bankId,
            id: data.polisOnlineCode,
            status: status,
            result: result,
        });
    },
    deleteInsuranceParameters(state, polisOnlineCode) {
        state.insuranceParameters[polisOnlineCode] = {
            hasGas: {},
            notHasGas: {},
            currentHasGas: false,
        };
    },
    deleteInsuranceResult(state, polisOnlineCode) {
        const index = state.insuranceResult.findIndex((el) => el.id === polisOnlineCode);
        state.insuranceResult.splice(index, 1);
    },
    applyRules(state, rules) {
        if (!state.additionalParameters) {
            return;
        }
        Object.values(state.additionalParameters).forEach((parameter) => (parameter.hidden = false));
        ["Block", "Show"].forEach((key) => {
            const block = key === "Block";
            rules[key].forEach((rule) => {
                const parameter = state.additionalParameters
                    ? state.additionalParameters[rule.parameter]
                    : null;
                if (!parameter) {
                    return;
                }
                let elementToChange;
                switch (rule["value_type"]) {
                    case "option":
                        elementToChange = parameter.values.find((value) => value.id === rule.value);
                        break;
                    case "parameter":
                    default:
                        elementToChange = parameter;
                        break;
                }
                if (elementToChange) {
                    elementToChange.hidden = block;
                    if (block && state.parameters[elementToChange.id]) {
                        delete state.parameters[elementToChange.id];
                        delete state.checkedAdditionalParameters[elementToChange.id];
                    }
                }
            });
        });
        buildAdditionalParametersCategoriesForm(state);
    },
    chooseBank(state, { id, idMarketplace }) {
        state.banks[id].bankChecked = !state.banks[id].bankChecked;
        if (state.choosedBanks.ids[id] === undefined) {
            state.choosedBanks.ids[id] = id;
            state.choosedBanks.idsMarketplaces[idMarketplace] = idMarketplace;
        }
        else {
            delete state.choosedBanks.ids[id];
            delete state.choosedBanks.idsMarketplaces[idMarketplace];
        }
        state.choosedBanks.count = Object.keys(state.choosedBanks.ids).length;
    },
    updateChoosedBanks(state, ids) {
        let choosed = state.choosedBanks.ids;
        if (Object.values(choosed).length !== 0) {
            for (let id in choosed) {
                if (!ids.includes(+id)) {
                    delete state.choosedBanks.ids[id];
                    state.choosedBanks.count -= 1;
                }
            }
        }
    },
    writeBankPreviewIds(state, data) {
        data.forEach((id) => {
            if (!state.bankPreviewIds.includes(id)) {
                state.bankPreviewIds.push(id);
            }
        });
    },
    writeBankPreviewValues(state, data) {
        state.bankPreviewValues[data.id] = data.value;
    },
    cleanBankPreview(state) {
        state.bankPreviewIds = [];
        state.bankPreviewValues = {};
    },
    toggleIsIncomeAccountable(state, bool) {
        state.isIncomeAccountable = bool;
    },
    toggleLoadingBanks(state, bool) {
        state.banksLoading = bool;
    },
    toggleLoadingPromos(state, bool) {
        state.promosLoading = bool;
    },
    toggleLoadingBankPreview(state, bool) {
        state.bankPreviewLoading = bool;
    },
    writePromotions(state, data) {
        if (state.banks[data.bankId]) {
            state.banks[data.bankId].allPromo[data.promoId] = new Promotion(data.promoData);
            if (data.promoData.handler === 1) {
                state.banks[data.bankId].promo[data.promoId] = new Promotion(data.promoData);
            }
        }
    },
    updateRequestPromotionCounter(state) {
        state.requestPromotionCounter++;
    },
    writeDiscountRate(state, data) {
        state.banks[data.id].currentPaidRateReduction = data.paidRateReduction;
        state.banks[data.id].minDiscountRate = Number((state.banks[data.id].minRate - (data.paidRateReduction?.discount ?? 0)).toFixed(2));
    },
    updateStatusCurrentPaidRateReduction(state, data) {
        state.banks[data.id].currentPaidRateReduction.active = data.active;
    },
    writeDuringConstructionPromo(state, data) {
        state.banks[data.id].duringConstructionPromo.rate = data.rate;
        state.banks[data.id].duringConstructionPromo.payment = data.payment;
        state.banks[data.id].duringConstructionPromo.overpayment = data.overpayment;
    },
    resetDuringConstructionPromo(state, data) {
        state.banks[data.id].duringConstructionPromo.rate = undefined;
        state.banks[data.id].duringConstructionPromo.payment = undefined;
        state.banks[data.id].duringConstructionPromo.overpayment = undefined;
    },
    updateDiscountRate(state, data) {
        // @ts-ignore
        state.banks[data.bankId].minDiscountRate = Number((+data.minRate -
            (state.banks[data.bankId].currentPaidRateReduction?.discount ?? 0)).toFixed(2));
    },
    resetDiscountRate(state, id) {
        state.banks[id].minDiscountRate = null;
        state.banks[id].overpaymentRateReduction = null;
        state.banks[id].paymentRateReduction = null;
        state.banks[id].incomeRateReduction = null;
        state.banks[id].currentPaidRateReduction = {};
    },
    writePaymentRateReduction(state, data) {
        state.banks[data.id].paymentRateReduction = data.paymentNumber;
    },
    writeIncomeRateReduction(state, data) {
        state.banks[data.id].incomeRateReduction = data.incomeRateReduction;
    },
    writeOverpaymentRateReduction(state, data) {
        state.banks[data.id].overpaymentRateReduction = Number(data.paymentNumber * data.creditTerm - data.creditSum);
    },
    disableRequest(state, bool) {
        state.stopRequest = bool;
    },
    openAlert(state, text) {
        state.alertModal["show"] = true;
        state.alertModal["text"] = text;
    },
    closeAlert(state) {
        state.alertModal["show"] = false;
        state.alertModal["text"] = "";
    },
    cancelPromoOrSalaryProject(state, typeCancel) {
        // @ts-ignore
        let bank = state.banks[state.confirmModal.bankId];
        Object.values(bank.promo).forEach((promo) => {
            if (promo.priority !== promoTypeAndPriority.promoPriorityEqualSPPriority) {
                if (typeCancel === "salaryProject") {
                    bank.salaryProject.applied = false;
                    bank.promo[promo.id].applied = true;
                    updateCountDiscount();
                }
                else if (typeCancel === "promo") {
                    bank.salaryProject.applied = true;
                    bank.promo[promo.id].applied = false;
                    updateCountDiscount();
                }
            }
        });
        /** Обновление счетчика выбранных акций */
        function updateCountDiscount() {
            bank.countDiscount = 0;
            // @ts-ignore
            Object.values(bank.promo).forEach((promo) => {
                if (promo.applied)
                    bank.countDiscount++;
            });
        }
        state.confirmModal = {
            show: false,
            bankId: null,
            text: "",
        };
    },
    blockCalculator(state, bool) {
        state.isBlockCalculator = bool;
    },
    writeTypesLoan(state, value) {
        state.typesLoan = value;
    },
    pushCategory(state, category) {
        category.inputs.forEach((el) => {
            if (state.parameters[el.id]) {
                el.value = state.parameters[el.id].value;
                el.changed = true;
            }
        });
        state.categories.push(category);
    },
    deleteCategory(state, categoryId) {
        state.categories = state.categories.filter((el) => el.id !== categoryId);
    },
    updateParameters(state) {
        let parameters = [];
        let addParameters = {};
        state.categories.forEach((el) => {
            parameters = [...parameters, ...el.inputs.map((el) => el.id)];
        });
        Object.values(state.additionalParametersCategoriesForm).forEach((el) => {
            Object.values(el.subCategories).forEach((category) => {
                category.parameters.forEach((el) => {
                    addParameters[+el.id] = el.valueType;
                });
            });
        });
        const add2Parameters = Object.keys(state.parameters).filter((el) => Object.keys(addParameters).includes(el));
        add2Parameters.forEach((paramId) => {
            this.commit("calculator/writeCheckedAdditionalParameters", {
                id: paramId,
                value: addParameters[paramId] === "boolean"
                    ? state.parameters[paramId].value === 1
                    : state.parameters[paramId].value,
            });
        });
        const deletedParameters = Object.keys(state.parameters).filter((el) => ![
            ...parameters,
            ...Object.keys(addParameters).map((el) => +el),
        ].includes(+el));
        deletedParameters?.forEach((deletedId) => {
            delete state.parameters[deletedId];
        });
    },
    updateInput(state, { inputId, value }) {
        const categoryIndex = state.categories.findIndex((category) => {
            return category.inputs.some((input) => input.id === inputId);
        });
        const inputIndex = getInputIndex(state, inputId, categoryIndex);
        if (categoryIndex !== -1 && inputIndex !== -1) {
            updateInputWithRules(state, inputIndex, categoryIndex, value);
        }
    },
    deleteInput(state, inputId) {
        const categoryIndex = state.categories.findIndex((category) => {
            return category.inputs.some((input) => input.id === inputId);
        });
        const inputIndex = getInputIndex(state, inputId, categoryIndex);
        if (categoryIndex !== -1 && inputIndex !== -1) {
            delete state.parameters[inputId];
            state.categories[categoryIndex].inputs.splice(inputIndex, 1);
        }
    },
    changeInput(state, payload) {
        const categoryIndex = state.categories.findIndex((category) => {
            return category.inputs.some((input) => input.id === payload.id);
        });
        const inputIndex = getInputIndex(state, payload.id, categoryIndex);
        if (categoryIndex !== -1 && inputIndex !== -1) {
            Object.keys(payload).map((key) => {
                if (key in state.categories[categoryIndex].inputs[inputIndex]) {
                    state.categories[categoryIndex].inputs[inputIndex][key] =
                        payload[key];
                }
            });
            if (payload.changeId) {
                state.categories[categoryIndex].inputs[inputIndex].id =
                    payload.changeId;
            }
        }
    },
    pushInput(state, { categoryIndex, input, index }) {
        const inputBase = new newCategoryInput(input);
        if (categoryIndex !== -1) {
            if (index) {
                state.categories[categoryIndex].inputs.splice(index, 0, inputBase);
            }
            else {
                state.categories[categoryIndex].inputs.push(inputBase);
            }
        }
    },
    writeShowRewardUser(state, payload) {
        state.showRewardUser = payload;
    },
    writeShowPreferencesUser(state, payload) {
        state.showPreferencesUser = payload;
    },
    toggleLoadingTemplates(state, bool) {
        state.templates.loading = bool;
    },
    writeTemplates(state, templates) {
        Object.keys(templates).forEach((item) => {
            if (item === "selfTemplates") {
                if (templates[item].length) {
                    state.templates[item] = [
                        {
                            id: 0,
                            title: "Мои запросы",
                            ungroupedTemplates: templates[item],
                        },
                    ];
                }
            }
            else if (templates[item].groups || templates[item].ungroupedTemplates) {
                const flatten = (obj) => {
                    let templates = [...obj.groupTemplates];
                    if (obj.groups?.length) {
                        obj.groups.forEach((group) => {
                            if (flatten(group).length) {
                                const text = {
                                    id: group.id,
                                    title: group.title,
                                    ungroupedTemplates: [...flatten(group)],
                                };
                                templates.push(text);
                            }
                        });
                    }
                    return templates;
                };
                let newTemplates = [
                    ...templates[item].ungroupedTemplates,
                ];
                templates[item].groups.forEach((group) => {
                    if (flatten(group).length) {
                        const temp = {
                            id: group.id,
                            title: group.title,
                            ungroupedTemplates: [...flatten(group)],
                        };
                        newTemplates.push(temp);
                    }
                });
                state.templates[item] = newTemplates;
            }
            else {
                state.templates[item] = templates[item];
            }
        });
        // if (process.env["VUE_APP_IS_WIDGET"] === "true") {
        //   state.templates["publicTemplates"] = [];
        // }
        state.templates["loading"] = false;
        state.templates["selectedTemplate"] =
            state.templates["selectedTemplate"] || undefined;
    },
    createTemplate(state, data) {
        createTemplateFunc(state, data);
    },
    selectTemplate(state, template) {
        state.templates.selectedTemplate = template;
    },
    writeInfoAssessment(state, data) {
        state.infoAssessment["decorationType"] = data.decorationType;
    },
    changeTemplateTitle(state, data) {
        const index = state.templates[data.location].findIndex((template) => template.id === data.id);
        if (index >= 0) {
            state.templates[data.location][index].title = data.title;
        }
        else {
            const findIndex = (obj) => {
                if (obj.ungroupedTemplates) {
                    const index = obj.ungroupedTemplates?.findIndex((template) => template.id === data.id);
                    if (index >= 0) {
                        obj.ungroupedTemplates[index].title = data.title;
                    }
                    else {
                        obj.ungroupedTemplates.forEach((temp) => {
                            findIndex(temp);
                        });
                    }
                }
            };
            state.templates[data.location].forEach((el) => {
                findIndex(el);
            });
        }
    },
    deleteTemplateById(state, data) {
        deleteTemplate(state, data);
    },
    changeVisibilityTemplateById(state, data) {
        if (data.visible) {
            const index = state.templates.selfTemplates[0].ungroupedTemplates.findIndex((template) => template.id === data.template.id);
            state.templates.selfTemplates[0].ungroupedTemplates.splice(index, 1);
            state.templates[data.newLocation].push(data.template);
        }
        else {
            deleteTemplate(state, {
                id: data.template.id,
                location: data.location,
            });
            createTemplateFunc(state, data.template);
        }
    },
    toggleVersion(state, bool) {
        state.isClientVersion = bool;
    },
    toggleIsReduceRate(state, bool) {
        state.isReduceRate = bool;
    },
    writeBankAdvertises(state, data) {
        if (data) {
            Object.keys(data).forEach((bankId) => (state.bankAdvertises[bankId] = data[bankId]));
        }
    },
    writeBanksLogos(state, data) {
        Object.keys(data).forEach((bankId) => (state.banksLogos[bankId] = data[bankId]));
    },
    toggleSecondFormHasFilledValues(state, bool) {
        state.secondFormHasFilledValues = bool;
    },
    toggleIsFirstBlockValid(state, bool) {
        state.isFirstBlockValid = bool;
    },
    toggleIsValid(state, bool) {
        state.isValid = bool;
    },
};
function updateInputWithRules(state, inputIndex, categoryIndex, value) {
    const input = state.categories[categoryIndex].inputs[inputIndex];
    if (input.value !== value) {
        state.categories[categoryIndex].inputs[inputIndex].changed = true;
        state.categories[categoryIndex].inputs[inputIndex].value = value;
    }
}
function getInputIndex(state, inputId, categoryIndexParam) {
    if (categoryIndexParam !== undefined) {
        if (categoryIndexParam === -1) {
            return -1;
        }
        return state.categories[categoryIndexParam].inputs.findIndex((el) => el.id === inputId);
    }
    const categoryIndex = state.categories.findIndex((category) => {
        return category.inputs.some((input) => input.id === inputId);
    });
    if (categoryIndex === -1) {
        return -1;
    }
    return state.categories[categoryIndex].inputs.findIndex((el) => el.id === inputId);
}
function buildAdditionalParametersCategoriesForm(state) {
    const categories = Object.values(state.additionalParametersCategories);
    state.additionalParametersCategoriesForm = categories
        .filter((category) => !category.hidden)
        .map((category) => new AdditionalParametersCategoryForm(category));
}
function createTemplateFunc(state, data) {
    if (state.templates.selfTemplates.length) {
        state.templates.selfTemplates[0].ungroupedTemplates.push(data);
    }
    else {
        state.templates.selfTemplates = [
            {
                id: 0,
                title: "Мои запросы",
                ungroupedTemplates: [data],
            },
        ];
    }
}
function deleteTemplate(state, data) {
    const index = state.templates[data.location].findIndex((template) => template.id === data.id);
    if (index >= 0) {
        state.templates[data.location].splice(index, 1);
    }
    else {
        const findIndex = (arr, obj) => {
            if (obj.ungroupedTemplates) {
                const index = obj.ungroupedTemplates?.findIndex((template) => template.id === data.id);
                if (index >= 0) {
                    obj.ungroupedTemplates.splice(index, 1);
                    if (!obj.ungroupedTemplates.length) {
                        const objIndex = arr.findIndex((el) => el === obj);
                        arr.splice(objIndex, 1);
                    }
                }
                else {
                    obj.ungroupedTemplates.forEach((temp) => {
                        findIndex(obj.ungroupedTemplates, temp);
                    });
                }
            }
        };
        state.templates[data.location].forEach((el) => {
            findIndex(state.templates[data.location], el);
        });
    }
}
