export const mutations = {
    writeLoginData(state, data) {
        state.chief = data.chief;
        state.login = data.login;
        state.bonuses = data.bonuses;
        state.isFree = 1;
        state.isBusiness = data.isBusiness;
        state.isCheck = data.isCheck;
        state.licenseAgreement = data.license_agreement;
        state.settings = {
            hideAdditionalParams: !!data.settings.hide_additional_params,
            hideBaseContacts: !!data.settings.hide_base_contacts,
            hideSelectedPrograms: !!data.settings.hide_selected_programs,
            hideChecks: !!data.settings.hide_checks,
            hideDigest: !!data.settings.hide_digest,
        };
    },
    getUserTokenFromLocalStorage(state) {
        state.userToken = localStorage.getItem("User-token") || undefined;
    },
    writeUserToken(state, token) {
        state.userToken = token;
        localStorage.setItem("User-token", token);
    },
    deleteUserToken(state) {
        state.userToken = undefined;
        localStorage.removeItem("User-token");
    },
    writeParticipantList(state, data) {
        state.participants = data ?? [];
    },
    addParticipant(state, data) {
        state.participants = [...state.participants, data];
    },
    editParticipant(state, data) {
        const index = state.participants.findIndex((participant) => participant.id === data.id);
        state.participants[index].id = data.id;
        state.participants[index].login = data.login;
    },
    deleteParticipant(state, id) {
        const index = state.participants.findIndex((participant) => participant.id === id);
        state.participants.splice(index, 1);
    },
    toggleLicenseAgreement(state, bool) {
        state.licenseAgreement = bool;
    },
};
