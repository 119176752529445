<template>
  <v-sheet
    class="BSW-checkbox-input__wrapper"
    :class="{
      'BSW-checkbox-input__wrapper_with-description':
        info !== undefined && info !== '' && !customActivator,
    }"
    :width="width"
    :height="height"
  >
    <v-checkbox
      class="BSW-checkbox-input"
      :class="[
        { 'BSW-checkbox-input__disabled': disabled },
        { 'BSW-checkbox-input__success': success },

        { 'BSW-checkbox-input__noneBorder': noneBorder },
      ]"
      :style="styleCheckbox"
      hide-details
      :disabled="disabled"
      :success="success"
      v-model="inputValue"
      @click.native="changeCheckbox"
      @click="checkbox"
      :color="color"
    >
      <template #prepend>
        <label class="BSW-checkbox-input__label" :class="{ disabled }">
          <span v-if="disabled && labelDisabled">{{ labelDisabled }}</span>
          <span
            v-else-if="customActivator && info !== undefined && info !== ''"
            class="BSW-checkbox-input__label__custom-activator"
            @click.stop="customActivatorModal = true"
            >{{ label }}</span
          >
          <span
            v-else
            :class="{ 'BSW-checkbox-input__label-nowrap': nowrap }"
            >{{ label }}</span
          >
        </label>
      </template>
    </v-checkbox>

    <InputDescription
      class="BSW-checkbox-input__description"
      v-if="showDescription"
      :content="info"
      :title="label"
      @click.native.stop
      :custom-activator-modal="customActivatorModal"
      :custom-activator="customActivator"
      @close="customActivatorModal = false"
    />
  </v-sheet>
</template>

<script>
import InputDescription from "@/calculator/components/CommonComponents/Inputs/InputDescription";
import { mapState } from "vuex";

export default {
  name: "CheckboxInput",
  components: { InputDescription },
  props: {
    value: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    bgColor: {
      type: String,
    },
    info: {
      type: String,
    },
    showDescription: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    labelDisabled: {
      type: String,
    },
    success: {
      type: Boolean,
      default: false,
    },
    customActivator: {
      type: Boolean,
    },
    noneBorder: {
      type: Boolean,
      default: false,
    },
    styleCheckbox: {
      type: Object,
    },
    nowrap: {
      type: Boolean,
    },
    color: {
      type: String,
      default: "#0097CE",
    },
  },

  data() {
    return {
      inputValue: this.value,
      customActivatorModal: false,
    };
  },

  computed: {
    ...mapState("calculator", ["showRewardUser"]),
  },

  methods: {
    changeCheckbox() {
      if (!this.disabled) {
        this.inputValue = !this.inputValue;
        this.$emit("change", this.inputValue);
        this.$emit("changeCheckbox");
      }
    },
    checkbox() {
      if (!this.disabled) this.inputValue = !this.inputValue;
    },
  },

  updated() {
    this.inputValue = this.value;
  },

  watch: {
    value() {
      this.inputValue = this.value;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../../styles/variables";
.BSW-checkbox-input {
  border: 1px solid $dopColor;
  border-radius: $s-border-radius;
  width: 100%;
  display: grid !important;
  grid-template-columns: 1fr 20px;
  align-items: center;
  padding: 8px 15px;
  margin-top: 0;
  margin-right: 0 !important;
  cursor: pointer;

  //&__disabled {
  //  background-color: #ebebeb;
  //  color: #000;
  //  border: none;
  //  grid-template-columns: 1fr;
  //}

  &__success {
    border-color: #4caf50;
    border-width: 2px;
  }

  &__noneBorder {
    border: none;
  }

  &__wrapper {
    display: flex;
    border-radius: $s-border-radius;

    &_with-description {
      background-color: transparent;
      gap: 9px;
    }
  }

  &__label {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
    cursor: pointer;
    position: relative;
    width: 100%;

    &-nowrap {
      position: absolute;
      max-width: 100%;
      top: -13px;
      left: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.disabled {
      color: $dopColor;
    }

    &__custom-activator {
      color: #008ece;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background-color: #008ece;
        transition: 0.3s;
      }

      &:hover:before {
        width: 100%;
        transition: 0.3s;
      }
    }
  }

  &__info-btns {
    margin-left: 7px;
    display: grid;
    row-gap: 2px;
    align-content: center;
  }

  &__description {
  }
}
</style>
