import Cookies from "js-cookie";
export const GetWidgetToken = () => {
    const token = document.getElementById("widgetConfig").dataset.wid;
    return token ?? "";
};
export const GetUserWidgetToken = () => {
    let token = document.getElementById("widgetConfig").dataset.wcrutbs;
    if (!token) {
        token = Cookies.get("wcrutbs") ?? "";
    }
    return token;
};
